import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getAuth, signInWithCustomToken, signOut} from 'firebase/auth';
import {getFirestore, collection, getDocs} from 'firebase/firestore';

export const setup = createAsyncThunk(
    'app/setup',
    (email, thunk) => {
        return httpsCallable(getFunctions(), 'setup')({
            waiver: true,
            email
        }).then(result => {
            if (result.data.status === 'ok') {
                const auth = getAuth();
                signOut(auth).then(() => {
                    return signInWithCustomToken(auth, result.data.payload)
                        .then(console.info)
                        .catch(console.error)
                })
            }
        });
    }
)

export const loadLoops = createAsyncThunk(
    'app/loops',
    (arg, {rejectWithValue}) => {
        return getDocs(collection(getFirestore(), 'loops'))
            .then(result => {
                return result.docs;
            })
            .catch(rejectWithValue);
    }
)

export const fetch = createAsyncThunk(
    'app/fetch',
    (arg, {rejectWithValue}) => {
        return httpsCallable(getFunctions(), 'fetch')({
        }).then(result => {
            if (result.data.status === 'ok') {
                return result.data.payload;
            } else return rejectWithValue(result.data.payload);
        });
    }
)

export const VIEW_EFFORTS = 'efforts';
export const VIEW_LOOPS = 'loops';

const app = createSlice({
    name: 'app',
    initialState: {
        setup: false,
        init: false,
        view: VIEW_EFFORTS,
        user: null,
        loops: [],
        efforts: [],
    },
    reducers: {
        login: (state, action) => {
            const user = action.payload.claims;
            state.init = true;
            state.setup = false;
            state.user = user;

        },
        logout: (state, action) => {
            if (!state.setup) {
                state.init = true;
            }
            state.user = null;
        },
        updateView: (state, action) => {
            state.view = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(setup.pending, (state, action) => {
           state.init = false;
           state.setup = true;
        });
        builder.addCase(fetch.fulfilled, (state, action) => {
            state.efforts = [...action.payload.efforts];
        });
        builder.addCase(loadLoops.fulfilled, (state, action) => {
            state.loops = [...action.payload.map(d => d.data())]
        });
    }
});

export const { login, logout, updateView } = app.actions;
export default app.reducer;

