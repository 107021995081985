import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk'
import app from './app/state';

const reducer = combineReducers({ app })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
    reducer,
    composeEnhancers(applyMiddleware(thunkMiddleware))
);
