import React from 'react';
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {enableAllPlugins} from "immer"
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import store from './state.js';
// import initFirebase from './firebase';
import AppPage from './app/AppPage';
// import AuthPage from './app/AuthPage';
// import RedeemPage from "./app/RedeemPage";


// initialize immer
enableAllPlugins();

// initialize firebase
// initFirebase(store);

// initialize mui theme
const theme = createTheme({
  typography: {
    fontFamily: 'OpenSans, sans-serif'
  },
  palette: {
    primary: {
      main: '#002856'
    },
    secondary: {
      main: '#c2d5ed'
    },
    background: {
      default: '#f7fafc'
    }
  },
  shadows: [
    'none',
    'rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)'
  ]
});

const App = () => {
  return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <Router>
            <Routes>
              <Route path="/" element={<AppPage />} />
              {/*<Route path="/auth" element={<AuthPage />} />*/}
              {/*<Route path="/redeem/:coupon" element={<RedeemPage />} />*/}
            </Routes>
          </Router>
        </ThemeProvider>
      </Provider>
  )
}
export default App;
