// import {useSelector} from 'react-redux';
import {Box, /*CircularProgress*/} from '@mui/material';
// import Main from './Main';
import Landing from './Landing';


const AppPage = () => {
    // const isInit = useSelector(state => state.app.init === true);
    // const isWorking = useSelector(state => state.app.working === true);
    // const isLoggedIn = useSelector(state => state.app.user != null);

    // const isPending = !isInit || isWorking;
    return (
        <Box sx={{ flexGrow: 1}}>
            <Landing/>
            {/*{isPending && <CircularProgress size="6rem" sx={{*/}
            {/*    position: 'absolute',*/}
            {/*    top: '40%',*/}
            {/*    left: '50%',*/}
            {/*    transform: 'translate(-50%,-50%)',*/}
            {/*}}/>}*/}
            {/*{!isPending && !isLoggedIn && <Landing/>}*/}
            {/*{!isPending && isLoggedIn && <Main/>}*/}
        </Box>
    )
}

export default AppPage;
