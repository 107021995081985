import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Stack,
    styled,
    SvgIcon,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import sprinkles from '../assets/sprinkles.png';
import cupcakes from '../assets/photos/cupcake-shelves.jpg';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as ConnectIcon } from '../assets/strava/connect.svg';
import Em from '../util/Em';

const Pattern = styled(Box)(
    ({theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundImage: `url('${sprinkles}')`,
        backgroundColor: theme.palette.primary.main
    })
);

const Landing = () => {
    const theme = useTheme();
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));
    const callback = `${window.location.origin}/auth`

    return (
        <Container>
            <Box sx={{
                width: isLarge ? '50%' : '75%',
                maxWidth: 512,
                mx: 'auto',
                px: 1,
                py: 6}}>
                <Box component='img' src={cupcakes} sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: '100%',
                    objectFit: 'cover',
                    mx: 'auto'
                }} mb={isLarge?8:2}/>
                <SvgIcon component={Logo} inheritViewBox sx={{width: '100%', height: 'auto'}} color="primary"/>
                {/*<Box mt={isLarge?8:2}>*/}
                {/*    <Button sx={{width: '100%'}} component="a"*/}
                {/*            href={`https://www.strava.com/oauth/authorize?client_id=80687&redirect_uri=${callback}&response_type=code&approval_prompt=auto&scope=activity:read,activity:read_all`}>*/}
                {/*        <SvgIcon component={ConnectIcon} inheritViewBox sx={{width: '100%', height: 'auto'}}/>*/}
                {/*    </Button>*/}
                {/*</Box>*/}
            </Box>
            <Stack gap={2}>
                <Typography variant="h6">We’d like to send a big <Em sx={{fontStyle: 'normal'}}>THANK YOU</Em> to the everyone who pedalled their way through this year’s Tour de Crave!</Typography>
                <Typography variant="h6">Over the past three years:</Typography>
                <Box component="ul" sx={{my:0}}>
                    <li>
                        <Typography variant="h6">You cycled <Em>58,000</Em> km</Typography>
                    </li>
                    <li>
                        <Typography variant="h6">We handed out <Em>2,600</Em> water bottles</Typography>
                    </li>
                    <li>
                        <Typography variant="h6">And we enjoyed <Em>2,600</Em> cupcakes together!</Typography>
                    </li>
                </Box>
                <Typography variant="h6">
                    We hope you enjoyed exploring your neighbourhood (maybe even discovering some hidden gems!) and made some lasting memories. While this is the last year of the Tour de Crave, we’ve loved cheering you on from the sidelines and welcoming you in for a sweet taste of cupcake victory after your long ride! Who knows… maybe we’ll bring the Tour back one day.
                </Typography>
            </Stack>
        </Container>
        // <Grid container alignItems="center">
        //     {isLarge && <Grid item md={6}>
        //         <Pattern>
        //             <Paper sx={{mx:'auto', maxWidth: '90%', p: 4}}>
        //                 <Stack gap={2}>

        //                 </Stack>
        //             </Paper>
        //         </Pattern>
        //     </Grid>}
        //     <Grid item md={6} sx={{textAlign: 'center'}}>
        //
        //     </Grid>
        // </Grid>
    )
}

export default Landing;
